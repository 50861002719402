<template>
  <div class="sys-user">
    <ul class="infinite-list" style="width: 320px">
      <el-tree
          v-loading="deptLoading"
          class="el-tree"
          :data="treeList"
          draggable
          node-key="id"
          @node-click="handleNodeClick">
        <template #default="scope">
          <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
            <div style="margin: 5px">
              <i class="iconfont icon-wenjianjia" style="font-size: 16px"></i>
              <span style="font-size: 16px;margin-left: 10px">{{ scope.data.name }}</span>
            </div>
            <el-popover ref="popover" trigger="click" popper-class="my-el-popover" :width="240">
              <div style="display: flex;justify-content: space-around">
                <el-button type="text" @click="addNodes(scope.data,'equative')">新增同级(区域)</el-button>
                <el-button type="text" @click="addNodes(scope.data,'child')">新增子级(区域)</el-button>
              </div>
              <template #reference>
                <div style="margin-right: 10px;font-size: 25px;color: #409EFF">···</div>
              </template>
            </el-popover>
          </div>
        </template>
      </el-tree>
    </ul>

    <!--    新增子级区域,同级区域 弹框-->
    <el-dialog
        v-model="addNode"
        :title=addtitle
        width="1200px"
        :before-close="handleClose"
    >
      <el-form ref="ruleFormRef" :model="add" :rules="rules" label-width="140px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="添加名称" prop="name">
              <el-input style="margin-right:20px;" v-model="add.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="添加城市" prop="type">
              <el-select class="mr10" placeholder="省、市、区" v-model="add.type">
                <el-option label="省" :value="1"></el-option>
                <el-option label="市" :value="2"></el-option>
                <el-option label="县/区" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label=" 是否部门" prop="regionType">
              <el-select class="mr10" placeholder="是" v-model="add.regionType">
                <el-option label="是" :value=1></el-option>
                <el-option label="否" :value=2></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="add.regionType==1">
          <el-row>
            <el-col :span="8">
              <el-form-item label="公司名称" prop="companyName">
                <el-input style="margin-right:20px;" v-model="add.companyName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="中招/贝体" prop="flag">
                <el-select class="mr10" placeholder="中招/贝体" v-model="add.flag">
                  <el-option label="中招" :value="1"></el-option>
                  <el-option label="贝体" :value="2"></el-option>
                  <el-option label="高考" :value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收据章id" style="" prop="chapterId">
                <el-input style="margin-right:20px;" v-model="add.chapterId"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="合同模板id" prop="contractTemplate">
                <el-input style="margin-right:20px;" v-model="add.contractTemplate"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="财务姓名" prop="financialName">
                <el-input style="margin-right:20px;" v-model="add.financialName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label=" 出纳员姓名" prop="cashierName">
                <el-input style="margin-right:20px;" v-model="add.cashierName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="收据联系电话" prop="receiptPhone">
                <el-input style="margin-right:20px;" v-model="add.receiptPhone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收据的系付" prop="tiedPayment">
                <el-input style="margin-right:20px;" v-model="add.tiedPayment"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否直营" prop="tiedPayment">
                <el-select class="mr10" placeholder="直营\加盟" v-model="add.manageWay">
                  <el-option label="直营" :value="1"></el-option>
                  <el-option label="加盟" :value="0"></el-option>
                  <el-option label="第三方" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label=" 微信支付服务商商户单号" prop="mchId">
                <el-input style="margin-right:20px;" v-model="add.mchId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收据章图片" required>
                <el-upload
                    class="upload"
                    :http-request="uploadHttp"
                    :before-upload="beforeAvatarUpload"
                    :on-remove="handleRemove"
                    :class="{uoloadSty:thisImage==null ? true:false,disUoloadSty:thisImage!=null ? true:false}"
                    :limit=1
                    list-type="picture-card">
                  <el-button icon="Plus" text/>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="addNode = false">取消</el-button>
        <el-button type="primary" @click="confirmAddarea">
          确定
        </el-button>
      </span>
      </template>
    </el-dialog>

    <!--    校区信息展示-->
    <div style="width: 100%;margin:20px 10px 30px 10px">
      <div class="handle-box">
        <el-button type="primary" class="mr10" @click="openAddCampusDialog()">添加校区</el-button>
        <el-input placeholder="校区名称" style="width: 200px;" class="mr10" v-model="query.name"/>
        <el-select placeholder="校区类型" style="width: 140px;" class="mr10" v-model="query.campusType">
          <el-option label="中招体育" value="1"></el-option>
          <el-option label="少年" value="2"></el-option>
          <el-option label="高考体育" value="4"></el-option>
        </el-select>
        <el-select v-model="query.state" placeholder="状态" style="width: 140px;" class="mr10">
          <el-option label="全部" value=""></el-option>
          <el-option label="开启" value="开启"></el-option>
          <el-option label="关闭" value="关闭"></el-option>
        </el-select>
        <el-button type="primary" @click="handleSearch">搜索</el-button>
        <el-button type="primary" @click="exportEcell">导出</el-button>
        <el-button type="primary" @click="exportCampus">导出（新）</el-button>
      </div>
      <el-table :data="tableData" height="540px" stripe border>
        <el-table-column type="index" label="id" width="80">
          <template #default="scope">
            {{ scope.row.areaId }}
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip="true" prop="principalName" label="负责老师" width="100px"/>
        <el-table-column show-overflow-tooltip="true" prop="deptName" label="部门" width="100px"/>
        <el-table-column show-overflow-tooltip="true" label="校区名称" width="380">
          <template #default="scope">
            {{ scope.row.areaCity }} - {{ scope.row.pname }} - {{ scope.row.name }} -
            <span v-if="scope.row.flag === 1">中招</span>
            <span v-if="scope.row.flag === 2">贝体</span>
            <span v-if="scope.row.flag === 4">高考</span>
            <span v-if="scope.row.flag!==1 && scope.row.flag!==2 && scope.row.flag!==4">其他</span>
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip="true" prop="businessTime" label="营业时间"/>
        <el-table-column show-overflow-tooltip="true" prop="location" label="详情地址"/>
        <el-table-column
            label="状态"
            width="80">
          <template #default="scope">
            <el-tooltip :content="scope.row.state==='开启'? '关闭后此教练将无法使用':'确定要开启吗'"
                        placement="top">
              <el-switch
                  @click="setCampusState(scope.row,scope.row.state)"
                  v-model="scope.row.state"
                  active-value="开启"
                  inactive-value="关闭">
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="90px">
          <template #default="scope">
            <el-button
                type="text" size="small" style="margin: 0 auto;padding: 0"
                icon="el-icon-edit"
                @click="updateCampusItem(scope.row)">编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right;margin-top: 30px;display: flex;justify-content: flex-end">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!--编辑校区-->
    <update-campus :updateDialog="updateDialog" :campus="updateCampus" @closeDialog="updateDialog=false"/>

    <!--新增校区-->
    <add-campus :addDialog="addDialog" @closeDialog="addDialog=false"/>
  </div>
</template>

<script>
import {
  tree,
  selectCampusByAreaId,
  updateCampusState,
  addArea,
  exportArea, exportCampus
}
  from "../../api/area";
import {uploadImage} from '@/api/helpManual'
import addCampus from "./component/addCampus";
import updateCampus from "./component/UpdateCampus";

export default {
  name: "area",
  components: {addCampus, updateCampus},
  data() {
    return {
      pageTotal: 0,
      rules: {
        chapter_Id: [{
          required: true,
          message: '请添加收据章Id',
          trigger: 'blur',
        }],
        name: [{
          required: true,
          message: '请添加名称',
          trigger: 'blur',
        }],
        type: [{
          required: true,
          message: '请选择城市',
          trigger: 'change',
        }],
        regionType: [{
          required: true,
          message: '请选择部门',
          trigger: 'blur',
        }],
        companyName: [{
          required: true,
          message: '请添加公司名称',
          trigger: 'blur',
        }],
        flag: [{
          required: true,
          message: '请选择中招贝体',
          trigger: 'change',
        }],
      },
      thisImage: null,
      addtitle: '',
      add: {},
      addNode: false,
      addDialog: false,
      updateDialog: false,
      updateCampus: {},
      deptLoading: false,
      treeList: [],
      treeDialog: false,
      treeLoading: false,
      tableData: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        areaId: null,
        areaType: null,
        name: null,
        campusType: null
      },
      sysStudentType: localStorage.getItem('studentType'),
    }
  },
  methods: {
    handlePageChange(e) {
      this.query.pageIndex = e
      this.handleNodeClick()
    },
    openAddCampusDialog() {
      if (this.query.areaId) {
        this.addDialog = true
      } else {
        this.$message.error('添加校区前,请点击左侧区域,选择校区所属区域!')
      }
    },

    //确定添加城市
    confirmAddarea() {
      this.$refs['ruleFormRef'].validate((valid) => {
        if (valid) {
          if (this.add.regionType === 1) {
            if (!this.add.receiptChapterUrl) {
              this.$message.error('请添加图片')
            }
          }
          addArea(this.add).then(res => {
            if (res.code === 200) {
              this.addNode = false
              this.$message.success('添加成功')
              this.getData()
            } else {
              this.$message.error('添加失败')
            }
          })

        } else {
          this.$message.error('请完整填写信息')
        }
      })
    },
    //删除图片
    handleRemove() {
      this.thisImage = null;
    },
    uploadHttp(file) {
      this.thisImage = file.file
      const data = new FormData()
      data.append(
          'multipartFile', file.file
      )
      data.append(
          'flag', 'area'
      )
      data.append(
          'fileName', file.file.name
      )
      uploadImage(data).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.add.receiptChapterUrl = res.data
        } else {
          this.$message.error('上传图片失败')
        }
      })
    },
    //添加同级或添加子集
    addNodes(row, level) {
      if (level === 'equative') {
        this.addtitle = '添加同级城市'
        this.add.addType = 1
      } else {
        this.addtitle = '添加子级城市'
        this.add.addType = 2
      }
      this.add.addId = row.id
      this.addNode = true
    },
    getData() {
      tree(null).then(val => {
        if (val.code === 200) {
          this.treeList = val.data;
        } else {
          this.$message.error("失败！");
        }
      })
    },
    //点击区域查询校区
    handleNodeClick(data) {
      if (data) {
        this.query.areaId = data.id;
        this.query.areaType = data.type;
      }
      selectCampusByAreaId(this.query).then(val => {
        this.tableData = val.data.records;
        this.pageTotal = val.data.total
      })
    },
    //修改校区
    updateCampusItem(val) {
      this.updateDialog = true;
      this.updateCampus = val;
      console.log(val);
    },
    //调整校区状态
    setCampusState(row, state) {
      updateCampusState(row.areaId, state).then(res => {
        if (res.code === 200) {
          this.$message.success(`操作成功`);
        } else {
          this.$message.error(`操作失败`);
        }
      })
    },
    //搜索
    handleSearch() {
      // if(this.query.areaId) {
      selectCampusByAreaId(this.query).then(val => {
        this.tableData = val.data.records;
      })
      // }else{
      //   // this.$message.error('请选择省份!')
      // }
    },
    //导出
    exportEcell() {
      let query = this.query
      exportArea(query.name, query.pageIndex, query.pageSize, query.areaId).then(res => {
        let blob = new Blob([res], {type: "application/vnd.ms-excel"});
        console.log(blob);
        let objectUrl = URL.createObjectURL(blob);
        console.log(objectUrl);
        const link = document.createElement('a');//我们用模拟q标签点击事件
        const fname = '区域管理表'; //下载文件的名字
        link.href = objectUrl;
        link.setAttribute('download', fname);
        document.body.appendChild(link);
        /**
         * 在这期间，我们可以做自己界面的反应逻辑
         **/
        link.click();//点击
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(URL); // 释放掉blob对象
      })
    },
    exportCampus() {
      exportCampus(this.query).then();
    },
  },
  created() {
    this.getData();
  }
}
</script>

<style scoped>
.sys-user {
  background-color: #ffffff;
  height: 100%;
  display: flex
}

.sys-user .el-tree {
  height: 100%;
  width: 98%;
  border-right: 3px #EBEEF5 solid
}

::-webkit-scrollbar {
  width: 7px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c7c9ce;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #EBEEF5;
  background: #ededed;
  border-radius: 5px;
}

.query-item {
  margin: 20px 10px;
}

.handle-box {
  margin-bottom: 20px;
}

.mr10 {
  margin-right: 10px;
}
</style>
<style>

.el-popover.my-el-popover {
  width: 20px;
}

.el-cascader__tags {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}
</style>
