<template>
  <el-dialog title='编辑校区' v-model="updateCampusDialog" width="900px">
  <div style="padding:10px 50px 0 0">
    <el-form :rules="campusRules" ref="campusRules" :model="updateForm" label-width="120px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="校区名称" prop="name">
            <el-input v-model="updateForm.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="类型" prop="flag">
            <el-select @change="choiceFlag()" placeholder="类型" v-model="updateForm.flag">
              <div v-if="sysStudentType==3">
                <el-option label="中招体育" :value="1"></el-option>
                <el-option label="少年" :value="2"></el-option>
                <el-option label="高考体育" :value="4"></el-option>
              </div>
              <div v-if="sysStudentType!=3">
                <el-option v-if="sysStudentType==1" label="中招体育" :value='1'></el-option>
                <el-option v-else label="少年" value="2"></el-option>
                <el-option label="高考体育" value="4"></el-option>
              </div>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item  prop="principalId" label="负责人">
            <el-select
                :disabled="updateForm.flag==null ? true:false"
                v-model="updateForm.principalId"
                filterable placeholder="请选择">
              <el-option
                  v-for="item in coachList"
                  :key="item.coachid"
                  :label="item.coachname"
                  :value="item.coachid"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
<!--          <el-form-item prop="campusFlag" label="状态">-->
<!--            <el-select v-model="updateForm.campusFlag" placeholder="状态">-->
<!--              <el-option key="开课中" label="开课中" value="开课中"></el-option>-->
<!--              <el-option key="停课中" label="停课中" value="停课中"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
        </el-col>
        <el-col :span="8">
          <el-form-item label="经度" prop="longitude">
            <el-input v-model="updateForm.longitude"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="维度" prop="latitude">
            <el-input  v-model="updateForm.latitude"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>

      </el-row>
      <div style="display:flex;">
        <div>
          <el-form-item label="营业时间" prop="location">
            <el-input :rows="2" type="textarea" style="width: 420px" v-model="updateForm.businessTime"
                      placeholder="如：上午 6:00~7:30 下午 19:00~20:30"></el-input>
          </el-form-item>
          <el-form-item label="详情地址" prop="location">
            <el-input :rows="4" type="textarea" style="width: 420px" v-model="updateForm.location"
                      placeholder="如：**路与**路交叉路口向东50米"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="所属区域" prop="fatherId">
            <el-cascader
                v-model="updateForm.fatherId"
                :props="{
                   value: 'id',
                   label: 'name',
                   children: 'children',
                   checkStrictly: true}"
                :options="treeList"/>
          </el-form-item>
            <el-form-item label="排序" prop="sort">
              <el-input class="handle-select" v-model="updateForm.sort"></el-input>
            </el-form-item>
          <el-form-item label="所属部门" prop="deptId">
            <el-cascader
                v-model="updateForm.deptId"
                :props="{
                   value: 'id',
                   label: 'name',
                   children: 'children',
                   checkStrictly:'true'}"
                :options="deptList"/>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
  <template #footer>
      <span class="dialog-footer">
        <el-button @click="addCampusDialog = false">取消</el-button>
        <el-button type="primary" @click="updateCampus()">确定</el-button>
      </span>
  </template>
</el-dialog>
</template>

<script>
import {tree,editCampus} from "../../../api/area";
import {listCoachAll} from "@/api/coach";
import { listXcxDept } from "@/api/dept";
export default {
  name: "UpdateCampus",
  props:{
    updateDialog:String,
    campus:Object
  },
  data() {
    return {
      sysStudentType:localStorage.getItem('studentType'),
      updateCampusDialog:false,
      updateForm:{},
      deptList:[],
      campusRules: {
        name: [{
          required: true,
          message: '请输入校区名称！',
          trigger: 'change'
        },],
        principalId: [{
          required: true,
          message: '请选择校区负责人！',
          trigger: 'change'
        },],
        flag: [{
          required: true,
          message: '请选择类型！',
          trigger: 'change'
        }],
        // campusFlag: [{
        //   required: true,
        //   message: '请选择校区当前状态！',
        //   trigger: 'change'
        // }],
        longitude: [{
          required: true,
          message: '请输入经度！',
          trigger: 'change'
        }],
        latitude: [{
          required: true,
          message: '请输入维度！',
          trigger: 'change'
        }],
        sort: [{
          required: true,
          message: '请输入排序！',
          trigger: 'change'
        }],
        location: [{
          required: true,
          message: '请输入校区详细地址！',
          trigger: 'change'
        }],
        fatherId: [{
          required: true,
          message: '请选择所属区域！',
          trigger: 'change'
        }],
        deptId: [{
          required: true,
          message: '请选择所属部门！',
          trigger: 'change'
        }],
      },
      coachList:[],
      treeList:[]
    }
  },
  methods:{
    /**
     *选择类型查询教练
     */
    choiceFlag(){
      listCoachAll(this.updateForm.flag).then(val => {
        if (val.code === 200) {
          this.coachList = val.data
        } else {
          this.$message.error(`请求校区负责人失败！`);
        }
      })
    },
    getCoachList(type){
      listCoachAll(type).then(val => {
        if (val.code === 200) {
          this.coachList = val.data;
        } else {
          this.$message.error(`请求校区负责人失败！`);
        }
      })
    },
    updateCampus(){
      this.$refs['campusRules'].validate((valid) => {
        if (valid){
          this.updateForm.fatherId = this.updateForm.fatherId[this.updateForm.fatherId.length-1]??this.updateForm.fatherId;

          if (Array.isArray(this.updateForm.deptId)){
            this.updateForm.deptId = this.updateForm.deptId[this.updateForm.deptId.length - 1];
          }else {
            this.updateForm.deptId = null;
          }

       editCampus(this.updateForm).then(value => {
            this.$emit("closeDialog");
            if(value.code == 200){
              this.$message.success("更新成功！");
            }else {
              this.$message.error("更新失败！");
            }
          })
        }
      })
    },
    getTypeList(commodityType) {
      if (commodityType!=null){
        commodityType.forEach(items => {
          if (items!=null){
            if (items.children!=null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
  },
  created() {
    listXcxDept().then((res) => {
      this.deptList = this.getTypeList(res.data);
    });
    this.updateForm = this.campus;
    tree(null).then(val => {
      if (val.code === 200) {
        this.treeList = this.getTypeList(val.data);
      } else {
        this.$message.error("获取区域数据失败！");
      }
    })
  },
  watch: {
    campus(news){
      this.updateForm = news;
      this.getCoachList(news.flag);
    },
    updateDialog(news) {
      this.updateCampusDialog=news;
    },
    updateCampusDialog(){
      if (this.updateCampusDialog==false){
        this.$emit("closeDialog");
      }
    }
  }
}
</script>

<style scoped>

</style>
